<template>
  <div class="h-100 ob-ed">
    <OnboardingLayout :step="step" v-if="!isCompleted">
      <template v-slot:left-section>
        <div class="h-75 mt-4">
          <h2 class="ob-ed__title">{{ $t("onboarding.goals.heading") }}</h2>
          <p class="ob-ed__content mt-5">
            {{ $t("onboarding.goals.subheading") }}
          </p>
        </div>
      </template>
      <template v-slot:right-section>
        <div class="w-100 h-100 d-flex flex-wrap">
          <div class="ob-ed__main">
            <h2 class="ob-ed__title mb-4">
              {{ $t("onboarding.goals.hint") }}
            </h2>
            <div class="ob-lg__textarea">
              <Textarea
                rows="6"
                v-model="learning_goals"
                :limit="160"
                :hasLimit="true"
                :placeholder="$t('onboarding.goals.placeholder')"
              />
            </div>
          </div>
          <div class="ob-ed__cta">
            <b-button
              class="mr-3 ob-btn ob-btn-primary"
              @click="submitInterests"
              >{{ $t("general.next") }}</b-button
            >
          </div>
        </div>
      </template>
    </OnboardingLayout>
    <div v-else class="h-100">
      <b-row class="p-5 m-0 h-100 ob-success">
        <b-col md="7">
          <div class="h-100 ob-success__div">
            <div class="mb-3">
              <img
                src="@/assets/images/logo/whoa-logo-blue-en.svg"
                alt="Logo"
                width="auto"
                height="48"
              />
            </div>
            <div class="ob-success__img">
              <img
                src="@/assets/images/illustration/illustration-onboarding-completed.svg"
                alt="onboarding-completed"
                width="auto"
                height="auto"
              />
            </div>
          </div>
        </b-col>
        <b-col md="5">
          <div
            class="h-100 d-flex align-items-center justify-content-center ml-5"
          >
            <div class="ob-success__content">
              <h2 class="ob-interest__title">
                {{ $t("onboarding.get_started.title") }}
              </h2>
              <p>{{ $t("onboarding.get_started.subtitle") }}</p>
              <b-button
                class="ob-btn ob-btn-primary"
                @click="onStartLearning"
                >{{ $t("onboarding.get_started.button") }}</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import OnboardingLayout from "../Layout/OnboardingLayout";
import Textarea from "../WrapperComponents/Textarea.vue";

export default {
  props: ["step"],
  components: { OnboardingLayout, Textarea },
  data() {
    return {
      isCompleted: false,
      learning_goals: ""
    };
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    submitInterests() {
      let obj = {
        stage: "learning_goals",
        interests: this.learning_goals
      };
      this.$store.dispatch("updateOnboarding", obj).then(() => {
        this.isCompleted = true;
      });
    },
    onStartLearning() {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
<style lang="scss">
#search-modal {
  .modal-header {
    border-bottom: none;
    align-items: flex-end;
    .input-group {
      border: none;
      .input-group-text {
        border: none;
        background: transparent;
        display: flex;
        align-items: flex-end;
        padding-bottom: 0px;
        padding-right: 10px;
        .b-icon {
          font-size: 14px;
        }
      }
      .form-control {
        border: none;
        border-bottom: 1px solid #e5e5e5;
        padding-left: 0;
        padding-bottom: 0px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #25282b;
        box-shadow: none;
      }
    }
    button {
      font-size: 14px;
      padding-bottom: 0;
    }
  }
  .modal-body {
    padding-top: 5px;
    max-height: 400px;
    ul {
      padding: 0px 16px;
      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 7px;
        &:last-child {
          border-bottom: 0;
        }
        p {
          margin-bottom: 0;
          width: 80%;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.1px;
          color: rgba(82, 87, 92, 0.7);
        }
        .ob-btn {
          padding-right: 0;
          font-size: 12px;
          width: 20%;
          text-align: right;
        }
      }
    }
  }
}
.modal-backdrop {
  background: #e8e8e8;
}
</style>
